'use client';

import { useState, useEffect } from 'react';
import { Switch } from '@chakra-ui/react';
import { Card } from '@/frontend/components/elements/cards/Card';

import styles from './ActivateProgram.module.scss';

export const ActivateProgram = (props) => {
  useEffect(() => {
    setIsActivated(props.programSate);
  }, [props]);

  const [isActivated, setIsActivated] = useState(false);

  return (
    <Card className={styles.program}>
      <div className={styles.program__title}>プログラム有効</div>
      <div className={styles.program__content}>
        <Switch
          size='lg'
          isChecked={isActivated}
          onChange={() => props.postProgramState(isActivated)}
        />
        {isActivated ? (
          <div className={styles.program__content__activated}>有効化済み</div>
        ) : (
          <div className={styles.program__content__deactivated}>無効</div>
        )}
      </div>
    </Card>
  );
};
