import { CopyButton } from '@/frontend/components/elements/buttons/CopyButton';
import { HelpText } from '@/frontend/components/elements/helpText/HelpText';
import { MenuItem } from '@/frontend/components/elements/menu/MenuItem';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';

import iconAmbassador from '/icons/icon-ambassador.svg';
import iconGuest from '/icons/icon-guest.svg';
import iconHome from '/icons/icon-home.svg';
import iconEdit from '/icons/icon-edit.svg';
import iconMail from '/icons/icon-mail.svg';
import iconLight from '/icons/icon-light.svg';
import iconHuman from '/icons/icon-human.svg';
import iconBag from '/icons/icon-bag.svg';

import styles from './Menu.module.scss';

export const Menu = () => {
  return (
    <aside className={styles.menu}>
      <nav>
        <section className={styles.menu__section}>
          <ul className={styles.menu__section__ul}>
            <li className={styles.menu__section__li}>
              <MenuItem url='/' icon={iconHome} name='ホーム' active={true} />
            </li>
          </ul>
        </section>
        <section className={styles.menu__section}>
          <span className={styles.menu__section__title}>
            インセンティブ管理
          </span>
          <ul>
            <li>
              <MenuItem
                url='/incentive/ambassador'
                icon={iconAmbassador}
                name='アンバサダー'
              />
            </li>
            <li>
              <MenuItem url='/incentive/guest' icon={iconGuest} name='ゲスト' />
            </li>
          </ul>
        </section>
        <section className={styles.menu__section}>
          <span className={styles.menu__section__title}>ドキュメント管理</span>
          <ul>
            <li>
              <MenuItem
                url='/document/ambassador'
                icon={iconEdit}
                name='紹介ページ編集'
                forAmbassador={true}
              />
            </li>
            <li>
              <MenuItem
                url='/document/guest'
                icon={iconEdit}
                name='紹介ページ編集'
                forGuest={true}
              />
            </li>
            <li>
              <MenuItem
                url='/document/recruit_mail'
                icon={iconMail}
                name='招待メール設定'
                forAmbassador={true}
              />
            </li>
            <li>
              <MenuItem
                url='/document/join_mail'
                icon={iconMail}
                name='登録完了メール設定'
                forAmbassador={true}
              />
            </li>
            <li>
              <MenuItem url='' icon={iconLight} name='さらに使いこなす' />
            </li>
          </ul>
        </section>
        <section className={styles.menu__section}>
          <span className={styles.menu__section__title}>
            アンバサダー/ゲスト管理
          </span>
          <ul>
            <li>
              <MenuItem
                url='/ambassadors'
                icon={iconHuman}
                name='アンバサダー一覧'
              />
            </li>
            <li>
              <MenuItem url='/order' icon={iconBag} name='売り上げ注文一覧' />
            </li>
            <li>
              <MenuItem
                url='/ambassador/block'
                icon={iconMail}
                name='メールブロック一覧'
              />
            </li>
            <li>
              <MenuItem url='' icon={iconLight} name='さらに使いこなす' />
            </li>
          </ul>
        </section>
        <section className={styles.menu__section}>
          <ul>
            <li>
              <MenuItem url='/report' icon={iconEdit} name='レポート' />
            </li>
          </ul>
        </section>
        <section className={styles.menu__section}>
          <CopyButton text='リクルートURLをコピー' url='https://example.com' />
          <Spacer size='8px' />
          <HelpText
            text='リクルートURLとは？'
            label='リクルートURLは、アンバサダーに配布するURLです。'
          />
          <Spacer size='24px' />
          <ul className={styles.menu__links}>
            <li>
              <a href='/setting/account/'>アカウント管理</a>
            </li>
            <li>
              <a href='/setting/user'>ユーザー管理</a>
            </li>
            <li>
              <a href='/setting/test-user'>テストユーザー管理</a>
            </li>
          </ul>
        </section>
      </nav>
    </aside>
  );
};
