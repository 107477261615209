"use client";

import styles from './BannerItem.module.scss';

export const BannerItem = (props) => {
  return (
    <div className={styles.container}>
      <a href={props.link} className={styles.link}>
        <img src={props.image} alt={props.title} className={styles.image} />
      </a>
    </div>
  );
}