import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
} from '@chakra-ui/react';

import styles from './User.module.scss';
import { getUserList as getUserListApi } from '@/frontend/src/backendApi';

// TODO: ログインユーザー権限での表示分け
export const UserScreen = () => {
  const [userList, setUserList] = useState([]);

  // 登録ユーザー一覧情報取得
  const getUserList = async () => {
    const response = await getUserListApi();
    console.log('user_list');
    console.log(response.data.data);
    setUserList(response.data.data.user_list ?? []);
  };

  // fetch
  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      <h2 className={styles.title}>設定</h2>
      <Spacer size='24px' />
      <h3 className={styles.subtitle}>ユーザー管理</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではログインユーザーの編集ができます。
      </p>
      <Spacer size='24px' />

      <Card>
        <nav className={styles.tab}>
          <ul className={styles.tab__items}>
            <li className={styles.tab__item}>
              <a href='/setting/account/'>アカウント情報管理</a>
            </li>
            <li className={`${styles.tab__item} ${styles.tab__item__active}`}>
              <a href='/setting/user'>ユーザー管理</a>
            </li>
            <li className={styles.tab__item}>
              <a href='/setting/test_user'>テストユーザー管理</a>
            </li>
          </ul>
        </nav>

        <Spacer size='24px' />

        <div className={styles.section}>
          <h4 className={styles.section__title}>ユーザー情報</h4>
          <Spacer size='16px' />
          <Button size={'md'} variant={'solid'} colorScheme={'blue'}>
            新規追加
          </Button>

          <Spacer size='16px' />

          <div className={styles.table}>
            <TableContainer maxWidth={'100%'}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>メールアドレス</Th>
                    <Th>氏名</Th>
                    <Th width={140}>権限</Th>
                    <Th width={140}>状態</Th>
                    <Th width={100}>操作</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userList.map((user) => {
                    return (
                      <Tr key={user.id}>
                        <Td>{user.email}</Td>
                        <Td>{user.name}</Td>
                        <Td>{user.role_label}</Td>
                        <Td>{user.status_label}</Td>
                        <Td>
                          <Stack direction={'row'} spacing={'4px'}>
                            <Button
                              size={'xs'}
                              variant={'solid'}
                              colorScheme={'blue'}
                            >
                              編集
                            </Button>
                            <Button
                              size={'xs'}
                              variant={'outline'}
                              colorScheme={'red'}
                            >
                              削除
                            </Button>
                          </Stack>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Card>
    </>
  );
};
