import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
} from '@chakra-ui/react';

import styles from './TestUser.module.scss';
import { getTestUserList as getTestUserListApi } from '@/frontend/src/backendApi';

export const TestUserScreen = () => {
  const [testUserList, setTestUserList] = useState([]);

  // 登録テストユーザー一覧取得
  const getTestUserList = async () => {
    const response = await getTestUserListApi();
    console.log('test_user_list');
    console.log(response.data.data);
    setTestUserList(
      response.data.data.test_user_list.length != 0
        ? response.data.data.test_user_list.data
        : []
    );
  };

  // fetch
  useEffect(() => {
    getTestUserList();
  }, []);

  return (
    <>
      <h2 className={styles.title}>設定</h2>
      <Spacer size='24px' />
      <h3 className={styles.subtitle}>テストユーザー管理</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではLettersアプリのテスト購入用アカウントが設定できます。
        <br />
        <span>
          ※有効になっているテストユーザーが購入したCVに関しては請求に含まれません。
        </span>
      </p>
      <Spacer size='24px' />

      <Card>
        <nav className={styles.tab}>
          <ul className={styles.tab__items}>
            <li className={styles.tab__item}>
              <a href='/setting/account/'>アカウント情報管理</a>
            </li>
            <li className={styles.tab__item}>
              <a href='/setting/user'>ユーザー管理</a>
            </li>
            <li className={`${styles.tab__item} ${styles.tab__item__active}`}>
              <a href='/setting/test_user'>テストユーザー管理</a>
            </li>
          </ul>
        </nav>

        <Spacer size='24px' />

        <div className={styles.section}>
          <h4 className={styles.section__title}>テストユーザー情報</h4>
          <Spacer size='16px' />
          <Button size={'md'} variant={'solid'} colorScheme={'blue'}>
            新規追加
          </Button>

          <Spacer size='16px' />

          <div className={styles.table}>
            <TableContainer maxWidth={'100%'}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>メールアドレス</Th>
                    <Th width={140}>状態</Th>
                    <Th width={100}>操作</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {/* TODO: adminで出しわけ */}
                  {testUserList.map((user) => {
                    return (
                      <Tr key={user.id}>
                        <Td>{user.email}</Td>
                        <Td>{user.status == 1 ? '有効' : '無効'}</Td>
                        <Td>
                          <Stack direction={'row'} spacing={'4px'}>
                            <Button
                              size={'xs'}
                              variant={'solid'}
                              colorScheme={'blue'}
                              className={styles.button__toggle}
                            >
                              {user.status != 1 ? '有効' : '無効'}
                              {/* TODO: onClick */}
                            </Button>
                            <Button
                              size={'xs'}
                              variant={'outline'}
                              colorScheme={'red'}
                            >
                              削除
                            </Button>
                          </Stack>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Card>
    </>
  );
};
