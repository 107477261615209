'use client';

import { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { HelpText } from '@/frontend/components/elements/helpText/HelpText';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Badge,
  Radio,
  RadioGroup,
  Input,
  Button,
  Checkbox,
  Stack,
  Select,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Icon from '@mdi/react';
import { mdiThumbUpOutline } from '@mdi/js';

import styles from './Ambassador.module.scss';
import {
  getAmbIncentive as getAmbIncentiveApi,
  postAmbIncentive as postAmbIncentiveApi,
} from '@/frontend/src/backendApi';

export const AmbassadorScreen = () => {
  const [type, setType] = useState('amazon');
  const [couponType, setCouponType] = useState('ratio');
  const [amazonGiftStoreType, setAmazonGiftStoreType] = useState('manual');
  const [couponStoreType, setCouponStoreType] = useState('manual');
  const [
    amazonGiftStoreManualArrowDuplicate,
    setAmazonGiftStoreManualArrowDuplicate,
  ] = useState('manual');
  const [incentive, setIncentive] = useState({});
  const [incentiveInfo, setIncentiveInfo] = useState({});

  // input
  const [inputMinPrice, setInputMinPrice] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [inputIncentiveGrace, setInputIncentiveGrace] = useState('');

  // アンバサダーインセンティブ情報取得
  const getAmbIncentive = async () => {
    const response = await getAmbIncentiveApi();
    console.log('amb_incentive');
    console.log(response.data.data);
    if ('amazon' in response.data.data.incentive) {
      setType('amazon');
      setIncentiveInfo(response.data.data.incentive.amazon);
    } else if ('coupon' in response.data.data.incentive) {
      setType('coupon');
      setCouponType(
        response.data.data.incentive.coupon.origin_coupon_type == 1
          ? 'ratio'
          : 'fixed'
      );
      setIncentiveInfo(response.data.data.incentive.coupon);
    } else if ('custom' in response.data.data.incentive) {
      setType('custom');
      setIncentiveInfo(response.data.data.incentive.custom);
    } else {
      setType('none');
      setIncentiveInfo({});
    }
    setIncentive(response.data.data.incentive ?? {});
  };

  const setInputDefault = useCallback(() => {
    if (type === 'amazon') {
      setInputMinPrice(
        incentive.amazon ? incentive.amazon.origin_min_price : ''
      );
      setInputValue(incentive.amazon ? incentive.amazon.origin_value : '');
      setInputIncentiveGrace(
        incentive.amazon ? incentive.amazon.origin_incentive_grace : ''
      );
    } else if (type === 'coupon') {
      setInputMinPrice(
        incentive.coupon ? incentive.coupon.origin_min_price : ''
      );
      setInputValue(incentive.coupon ? incentive.coupon.origin_value : '');
      setInputIncentiveGrace(
        incentive.coupon ? incentive.coupon.origin_incentive_grace : ''
      );
    } else if (type === 'custom') {
      setInputValue(incentive.custom ? incentive.custom.origin_min_price : '');
    } else {
      setInputValue('');
    }
  }, [incentive, type]);

  const save = async () => {
    const params = {
      type: type,
      couponType: couponType,
      min_price: inputMinPrice == '' ? 0 : inputMinPrice,
      value: inputValue == '' ? 0 : inputValue,
      incentive_grace: inputIncentiveGrace == '' ? 0 : inputIncentiveGrace,
    };
    try {
      const response = await postAmbIncentiveApi(params);
      console.log(response);
    } catch (e) {
      console.error(e);
    }
  };

  // fetch
  useEffect(() => {
    getAmbIncentive();
  }, []);

  // set input
  useEffect(() => {
    setInputDefault();
  }, [setInputDefault]);

  const typeText = () => {
    let text = '';
    switch (type) {
      case 'amazon':
        text =
          'Amazonでご使用いただけるギフト券をインセンティブとして設定できます';
        break;
      case 'coupon':
        text =
          '自社ECサイトでご使用いただけるクーポンをインセンティブとして設定できます';
        break;
      case 'custom':
        text = '社会貢献や限定イベントへの参加券など自由にカスタマイズできます';
        break;
      default:
        text = '';
    }
    return text;
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log('acceptedFiles:', acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <h2 className={styles.title}>インセンティブ管理</h2>
      <p className={styles.description}>
        ここではアンバサダーのインセンティブを管理できます。
      </p>

      <Spacer size='24px' />

      <Card
        title='インセンティブ情報'
        label='アンバサダー'
        forAmbassador={true}
      >
        <div className={styles.info}>
          <table>
            <tbody>
              <tr>
                <th>インセンティブタイプ</th>
                <td>{incentiveInfo.type ?? '-'}</td>
              </tr>
              <tr>
                <th>インセンティブ内容</th>
                <td>{incentiveInfo.body ?? '-'}</td>
              </tr>
              <tr>
                <th>インセンティブ発生最小金額 (税込)</th>
                <td>{incentiveInfo.min_price ?? '-'}</td>
              </tr>
              <tr>
                <th>
                  インセンティブ発生猶予期間
                  <HelpText
                    label={
                      <div>
                        アンバサダーの成果(CV)確定までの猶予期間を設定してください。
                        <br />
                        ゲストの購入成立後からアンバサダーへのインセンティブ付与までの間に猶予期間を設けることが可能です。
                        <br />
                        キャンセル有効期間などに配慮の上、自由に設定してください。
                      </div>
                    }
                  />
                </th>
                <td>{incentiveInfo.incentive_grace ?? '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>

      <Spacer size='32px' />

      <Card title='編集'>
        <div className={styles.edit}>
          <div className={styles.edit__type}>
            <RadioGroup onChange={setType} value={type}>
              <Stack direction='row' className={styles.edit__type__stack}>
                <Radio value='amazon'>
                  <Badge
                    colorScheme='green'
                    className={styles.edit__type__recommend}
                  >
                    おすすめ！
                  </Badge>
                  Amazonギフト券
                </Radio>
                <Radio value='coupon'>クーポン</Radio>
                <Radio value='custom'>カスタム</Radio>
                <Radio value='none'>なし</Radio>
              </Stack>
            </RadioGroup>
            {type !== 'none' && (
              <>
                <Spacer size='18px' />
                <div className={styles.edit__type__description}>
                  <p>{typeText()}</p>
                </div>
              </>
            )}
          </div>

          <Spacer size='24px' />

          {type === 'amazon' && (
            <>
              <div className={styles.edit__amount}>
                <div className={styles.edit__title}>
                  1. ギフト金額
                  <Badge colorScheme='red'>必須</Badge>
                </div>
                <Spacer size='8px' />
                <Stack direction={'row'} spacing={'8px'} align='center'>
                  <Input
                    placeholder='5000'
                    htmlSize={10}
                    width={180}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <span>円</span>
                </Stack>
                <Spacer size='8px' />
                <Stack
                  direction={'row'}
                  spacing={'8px'}
                  align='center'
                  className={styles.edit__advice}
                >
                  <Icon
                    path={mdiThumbUpOutline}
                    size={'16px'}
                    color='rgba(128, 90, 213, 1)'
                  />
                  <span>設定アドバイス：商品価格の10〜15%を推奨しています</span>
                </Stack>
              </div>

              <Spacer size='40px' />

              <div className={styles.edit__gift}>
                <div className={styles.edit__title}>
                  2. アマゾンギフト新規登録
                  <Badge colorScheme='red'>必須</Badge>
                </div>
                <Spacer size='8px' />
                <div className={styles.edit__gift__container}>
                  <RadioGroup
                    onChange={setAmazonGiftStoreType}
                    value={amazonGiftStoreType}
                  >
                    <Stack
                      direction='row'
                      gap={'0px'}
                      className={styles.edit__gift__stack}
                    >
                      <Radio value='manual'>手動登録</Radio>
                      <Radio value='csv'>CSVファイルから登録</Radio>
                    </Stack>
                  </RadioGroup>
                  <Spacer size='24px' />
                  <div className={styles.edit__gift__setting}>
                    {amazonGiftStoreType === 'manual' && (
                      <div className={styles.edit__gift__manual}>
                        <span>コード</span>
                        <Spacer size='8px' />
                        <Input
                          placeholder='00001,00002,00003'
                          htmlSize={10}
                          width={260}
                        />
                        <Spacer size='8px' />
                        <Stack
                          direction={'row'}
                          spacing={'8px'}
                          align='center'
                          className={styles.edit__advice}
                        >
                          <Icon
                            path={mdiThumbUpOutline}
                            size={'16px'}
                            color='rgba(128, 90, 213, 1)'
                          />
                          <span>
                            設定アドバイス：カンマ区切りでまとめて登録できます
                          </span>
                        </Stack>
                        <Spacer size='24px' />
                        <Checkbox
                          onChange={setAmazonGiftStoreManualArrowDuplicate}
                          value={amazonGiftStoreManualArrowDuplicate}
                        >
                          重複したコードの登録を許可する
                        </Checkbox>
                        <Spacer size='16px' />
                        <Button
                          size={'md'}
                          variant={'solid'}
                          colorScheme='blue'
                          width={140}
                        >
                          登録
                        </Button>
                      </div>
                    )}

                    {amazonGiftStoreType === 'csv' && (
                      <div className={styles.edit__gift__csv}>
                        <div
                          {...getRootProps()}
                          className={`${styles.dropzone} ${
                            isDragActive && styles.dropzone__active
                          }`}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>ここにファイルをドロップしてください</p>
                          ) : (
                            <p>CSVファイルをここにドロップ</p>
                          )}
                        </div>
                        <Spacer size='24px' />
                        <Checkbox
                          onChange={setAmazonGiftStoreManualArrowDuplicate}
                          value={amazonGiftStoreManualArrowDuplicate}
                        >
                          重複したコードの登録を許可する
                        </Checkbox>
                        <Spacer size='16px' />
                        <Stack direction={'row'} spacing={'8px'} align='center'>
                          <Button
                            size={'md'}
                            variant={'solid'}
                            colorScheme='blue'
                            width={140}
                          >
                            登録
                          </Button>
                          <Button
                            size={'md'}
                            variant={'outline'}
                            colorScheme='gray'
                          >
                            CSVテンプレートをダウンロード
                          </Button>
                        </Stack>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <Spacer size='40px' />

              <div className={styles.edit__min_amount}>
                <div className={styles.edit__title}>
                  3. インセンティブ発生最小金額 (税込)
                  <HelpText label='ここに説明文' />
                </div>
                <Spacer size='8px' />
                <Stack direction={'row'} spacing={'8px'} align='center'>
                  <Input
                    placeholder='0'
                    htmlSize={10}
                    width={180}
                    value={inputMinPrice}
                    onChange={(e) => setInputMinPrice(e.target.value)}
                  />
                  <span>円</span>
                </Stack>
                <Spacer size='8px' />
                <Stack
                  direction={'row'}
                  spacing={'8px'}
                  align='center'
                  className={styles.edit__advice}
                >
                  <Icon
                    path={mdiThumbUpOutline}
                    size={'16px'}
                    color='rgba(128, 90, 213, 1)'
                  />
                  <span>設定アドバイス：対象商品の金額を入力してください</span>
                </Stack>
              </div>

              <Spacer size='40px' />

              <div className={styles.edit__period}>
                <div className={styles.edit__title}>
                  4. インセンティブ発生猶予期間
                  <Badge colorScheme='red'>必須</Badge>
                  <HelpText label='ここに説明文' />
                </div>
                <Spacer size='8px' />
                <Stack direction={'row'} spacing={'8px'} align='center'>
                  <Input
                    placeholder='14'
                    htmlSize={10}
                    width={180}
                    value={inputIncentiveGrace}
                    onChange={(e) => setInputIncentiveGrace(e.target.value)}
                  />
                  <span>日間</span>
                </Stack>
                <Spacer size='8px' />
                <Stack
                  direction={'row'}
                  spacing={'8px'}
                  align='center'
                  className={styles.edit__advice}
                >
                  <Icon
                    path={mdiThumbUpOutline}
                    size={'16px'}
                    color='rgba(128, 90, 213, 1)'
                  />
                  <span>
                    設定アドバイス：無条件返品期間などを設けている場合、その日数にあわせるなどを推奨しています。
                  </span>
                </Stack>
              </div>
              <Spacer size='40px' />
            </>
          )}

          {type === 'coupon' && (
            <>
              <div className={styles.edit__amount}>
                <div className={styles.edit__title}>
                  1. インセンティブ内容
                  <Badge colorScheme='red'>必須</Badge>
                </div>
                <Spacer size='8px' />
                <Stack direction={'row'} spacing={'16px'} align='start'>
                  <Select
                    size='md'
                    defaultChecked={couponType}
                    onChange={(e) => setCouponType(e.target.value)}
                    width={140}
                  >
                    <option value='ratio'>割合</option>
                    <option value='fixed'>定額</option>
                  </Select>
                  <Stack direction={'row'} spacing={'8px'} align='center'>
                    <Input
                      placeholder='10'
                      htmlSize={10}
                      width={180}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span>{couponType === 'ratio' ? '％' : '円'}</span>
                  </Stack>
                </Stack>
                <Spacer size='8px' />
                <Stack
                  direction={'row'}
                  spacing={'8px'}
                  align='center'
                  className={styles.edit__advice}
                >
                  <Icon
                    path={mdiThumbUpOutline}
                    size={'16px'}
                    color='rgba(128, 90, 213, 1)'
                  />
                  <span>
                    設定アドバイス：分かりやすくお得感を出すために1,000円OFFや10%OFFなどの設定をおすすめします。
                  </span>
                </Stack>
              </div>

              <Spacer size='40px' />

              <div className={styles.edit__coupon}>
                <div className={styles.edit__title}>
                  2. クーポンコード新規登録
                  <Badge colorScheme='red'>必須</Badge>
                </div>
                <Spacer size='8px' />
                <div className={styles.edit__coupon__discount}>
                  <Stack direction={'row'} spacing={'8px'} align='center'>
                    <p>ショップのディスカウントページでコードを取得</p>
                    <HelpText label='ここに説明文' />
                  </Stack>
                  <Spacer size='10px' />
                  <Button
                    size='md'
                    variant='solid'
                    colorScheme='blue'
                    rightIcon={<ExternalLinkIcon />}
                  >
                    ディスカウントページへ
                  </Button>
                </div>
                <Spacer size='16px' />
                <div className={styles.edit__coupon__container}>
                  <RadioGroup
                    onChange={setCouponStoreType}
                    value={couponStoreType}
                  >
                    <Stack
                      direction='row'
                      gap={'0px'}
                      className={styles.edit__coupon__stack}
                    >
                      <Radio value='manual'>手動登録</Radio>
                      <Radio value='csv'>CSVファイルから登録</Radio>
                    </Stack>
                  </RadioGroup>
                  <Spacer size='24px' />
                  <div className={styles.edit__coupon__setting}>
                    {couponStoreType === 'manual' && (
                      <div className={styles.edit__coupon__manual}>
                        <span>コード</span>
                        <Spacer size='8px' />
                        <Input
                          placeholder='00001,00002,00003'
                          htmlSize={10}
                          width={260}
                        />
                        <Spacer size='8px' />
                        <Stack
                          direction={'row'}
                          spacing={'8px'}
                          align='center'
                          className={styles.edit__advice}
                        >
                          <Icon
                            path={mdiThumbUpOutline}
                            size={'16px'}
                            color='rgba(128, 90, 213, 1)'
                          />
                          <span>
                            設定アドバイス：カンマ区切りでまとめて登録できます
                          </span>
                        </Stack>
                        <Spacer size='24px' />
                        <Checkbox
                          onChange={setAmazonGiftStoreManualArrowDuplicate}
                          value={amazonGiftStoreManualArrowDuplicate}
                        >
                          重複したコードの登録を許可する
                        </Checkbox>
                        <Spacer size='16px' />
                        <Button
                          size={'md'}
                          variant={'solid'}
                          colorScheme='blue'
                          width={140}
                        >
                          登録
                        </Button>
                      </div>
                    )}

                    {couponStoreType === 'csv' && (
                      <div className={styles.edit__coupon__csv}>
                        <div
                          {...getRootProps()}
                          className={`${styles.dropzone} ${
                            isDragActive && styles.dropzone__active
                          }`}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>ここにファイルをドロップしてください</p>
                          ) : (
                            <p>CSVファイルをここにドロップ</p>
                          )}
                        </div>
                        <Spacer size='24px' />
                        <Checkbox
                          onChange={setAmazonGiftStoreManualArrowDuplicate}
                          value={amazonGiftStoreManualArrowDuplicate}
                        >
                          重複したコードの登録を許可する
                        </Checkbox>
                        <Spacer size='16px' />
                        <Stack direction={'row'} spacing={'8px'} align='center'>
                          <Button
                            size={'md'}
                            variant={'solid'}
                            colorScheme='blue'
                            width={140}
                          >
                            登録
                          </Button>
                          <Button
                            size={'md'}
                            variant={'outline'}
                            colorScheme='gray'
                          >
                            CSVテンプレートをダウンロード
                          </Button>
                        </Stack>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <Spacer size='40px' />

              <div className={styles.edit__min_amount}>
                <div className={styles.edit__title}>
                  3. インセンティブ発生最小金額 (税込)
                  <HelpText label='ここに説明文' />
                </div>
                <Spacer size='8px' />
                <Stack direction={'row'} spacing={'8px'} align='center'>
                  <Input
                    placeholder='0'
                    htmlSize={10}
                    width={180}
                    value={inputMinPrice}
                    onChange={(e) => setInputMinPrice(e.target.value)}
                  />
                  <span>円</span>
                </Stack>
                <Spacer size='8px' />
                <Stack
                  direction={'row'}
                  spacing={'8px'}
                  align='center'
                  className={styles.edit__advice}
                >
                  <Icon
                    path={mdiThumbUpOutline}
                    size={'16px'}
                    color='rgba(128, 90, 213, 1)'
                  />
                  <span>
                    設定アドバイス：インセンティブが発生する最低金額を決めてください。
                  </span>
                </Stack>
              </div>

              <Spacer size='40px' />

              <div className={styles.edit__period}>
                <div className={styles.edit__title}>
                  4. インセンティブ発生猶予期間
                  <Badge colorScheme='red'>必須</Badge>
                  <HelpText label='ここに説明文' />
                </div>
                <Spacer size='8px' />
                <Stack direction={'row'} spacing={'8px'} align='center'>
                  <Input
                    placeholder='14'
                    htmlSize={10}
                    width={180}
                    value={inputIncentiveGrace}
                    onChange={(e) => setInputIncentiveGrace(e.target.value)}
                  />
                  <span>日間</span>
                </Stack>
                <Spacer size='8px' />
                <Stack
                  direction={'row'}
                  spacing={'8px'}
                  align='center'
                  className={styles.edit__advice}
                >
                  <Icon
                    path={mdiThumbUpOutline}
                    size={'16px'}
                    color='rgba(128, 90, 213, 1)'
                  />
                  <span>
                    設定アドバイス：無条件返品期間などを設けている場合、その日数にあわせるなどを推奨しています。
                  </span>
                </Stack>
              </div>
              <Spacer size='40px' />
            </>
          )}

          {type === 'custom' && (
            <>
              <div className={styles.edit__amount}>
                <div className={styles.edit__title}>
                  1. インセンティブ内容
                  <Badge colorScheme='red'>必須</Badge>
                </div>
                <Spacer size='8px' />
                <Input
                  type='text'
                  placeholder='売上の○%を▲▲に寄付'
                  htmlSize={10}
                  width={300}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <Spacer size='8px' />
                <Stack
                  direction={'row'}
                  spacing={'8px'}
                  align='center'
                  className={styles.edit__advice}
                >
                  <Icon
                    path={mdiThumbUpOutline}
                    size={'16px'}
                    color='rgba(128, 90, 213, 1)'
                  />
                  <span>おすすめ設定：売上の○%を▲▲に寄付</span>
                </Stack>
              </div>
              <Spacer size='40px' />
            </>
          )}

          <div className={styles.edit__button}>
            <Stack
              direction={'row'}
              spacing={'10px'}
              align='center'
              justify='center'
            >
              <Button
                colorScheme='blue'
                variant='outline'
                size={'lg'}
                width={240}
              >
                キャンセル
              </Button>
              <Button
                colorScheme='green'
                size={'lg'}
                width={240}
                onClick={save}
              >
                保存する
              </Button>
            </Stack>
          </div>
        </div>
      </Card>
    </>
  );
};
