'use client';

import { Spacer } from '../../elements/spacer/Spacer';
import { Card } from '../../elements/cards/Card';
import {
  Button,
  Stack,
  Input,
  Select,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
} from '@chakra-ui/react';
import { MdMailOutline } from 'react-icons/md';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/de.js';
import React, { useState } from 'react';

import styles from './JoinMail.module.scss';

export const JoinMailScreen = () => {
  const [isEdit, setIsEdit] = useState(false);
  const mailBody = `いつもありがとうございます。\n「CITAA」 紹介プログラム運営事務局です。\n\nこの度は、「CITAA」紹介プログラムにご登録いただき誠にありがとうございます。\n\nご登録者様専用リンクを発行いたしましたので、以下のURLをご確認の上、\nSNSやSMSなどで是非ともお知り合いの方にお知らせください。\n\n\n↓↓️ご登録者様専用リンクはこちらからご確認ください↓↓\n{{{REFERRAL_LINK}}}\n\n\n今後とも、「CITAA」をよろしくお願いいたします。`;

  const froalaConfig = {
    Key: 'YC5C3E3B2c1A6B5A4B3A3B2C2G3C5C1D-17dlB-9D-8iJ-7A-21A8sD-13B1yd==',
    height: 300,
    toolbarButtons: [
      [
        'fontFamily',
        'fontSize',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
      ],
      ['textColor', 'backgroundColor'],
      ['subscript', 'superscript'],
      [
        'alignLeft',
        'alignCenter',
        'formatOLSimple',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'paragraphFormat',
        'paragraphStyle',
        'lineHeight',
        'outdent',
        'indent',
        'quote',
      ],
      ['insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable'],
      ['undo', 'redo'],
      ['clearFormatting', 'selectAll', 'html'],
      ['fullscreen'],
    ],
  };

  return (
    <>
      <h2 className={styles.title}>ドキュメント管理</h2>
      <Spacer size='16px' />
      <h3 className={styles.subtitle}>登録完了メール設定</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではアンバサダーへの登録完了メールの見え方を設定します。
      </p>
      <Spacer size='24px' />

      <Card title='登録完了メール情報' forAmbassador={true}>
        <Spacer size='24px' />
        <div className={styles.info}>
          <TableContainer>
            <Table>
              <Tbody>
                <Tr>
                  <Th>メールタイトル</Th>
                  <Td>
                    「CITAA」紹介プログラムへのご参加ありがとうございます。
                  </Td>
                </Tr>
                <Tr>
                  <Th>メール本文</Th>
                  <Td>
                    {mailBody.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </Card>

      <Spacer size='32px' />

      <Card
        title='編集'
        id='edit'
        className={`${styles.edit} ${isEdit ? styles.edit__active : ''}`}
      >
        <Spacer size='16px' />

        <div className={styles.edit__form}>
          <form>
            <label className={styles.edit__form__label}>
              メールタイトル
              <Badge
                colorScheme='red'
                marginLeft='8px'
                style={{ background: 'rgba(229, 62, 62, 1)', color: '#ffffff' }}
              >
                必須
              </Badge>
            </label>
            <Spacer size='8px' />
            <Input
              size='md'
              variant='outline'
              placeholder='「CITAA」紹介プログラムへのご参加ありがとうございます。'
            />
            <Spacer size='32px' />

            <label className={styles.edit__form__label}>メールタイプ</label>
            <Spacer size='8px' />
            <Select size='md' w='320px'>
              <option value='text'>テキストメール</option>
              <option value='html'>HTMLメール</option>
            </Select>
            <Spacer size='32px' />

            <label className={styles.edit__form__label}>メール本文</label>
            <Spacer size='8px' />
            <FroalaEditorComponent tag='textarea' config={froalaConfig} />
            <Spacer size='8px' />
            <span className={styles.note}>
              {'※ {{{REFERRAL_LINK}}}という部分にご紹介用URLが入ります。'}
            </span>
            <Spacer size='32px' />

            <Stack direction='row' align='center' justify='center'>
              <Button
                size='sm'
                variant='outline'
                colorScheme='blue'
                children='テストメールを自分に送信'
                leftIcon={<MdMailOutline />}
              >
                テストメールを自分に送信
              </Button>
            </Stack>
          </form>
        </div>
      </Card>

      <div className={styles.action}>
        {!isEdit && (
          <Button
            size='lg'
            variant='solid'
            colorScheme={'blue'}
            className={styles.action__edit}
            width='450px'
            height='48px'
            onClick={() => {
              setIsEdit(!isEdit);
              const target = document.getElementById('edit');
              const rect = target.getBoundingClientRect().top;
              const offset = window.pageYOffset;
              const gap = 80;
              const targetY = rect + offset - gap;
              window.scrollTo({ top: targetY, behavior: 'smooth' });
            }}
          >
            編集する
          </Button>
        )}
        {isEdit && (
          <Stack
            direction='row'
            spacing='8px'
            width='100%'
            align='center'
            justify='center'
          >
            <Button
              size='lg'
              variant='outline'
              colorScheme={'blue'}
              className={styles.action__cancel}
              width='240px'
              height='48px'
              onClick={() => setIsEdit(!isEdit)}
            >
              キャンセル
            </Button>
            <Button
              size='lg'
              variant='solid'
              colorScheme={'blue'}
              className={styles.action__save}
              width='240px'
              height='48px'
            >
              保存する
            </Button>
          </Stack>
        )}
      </div>
    </>
  );
};
