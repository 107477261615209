import axios from 'axios';

// CSRF token set
axios.interceptors.request.use(
  (config) => {
    if (['post', 'put', 'patch', 'delete'].includes(config.method)) {
      config.headers['X-CSRF-Token'] =
        document.getElementsByName('csrf-token')[0].content;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * try/catch sample
 * @returns
 */
export async function getTodo() {
  try {
    return await axios.get(`/api/v1/todo`);
  } catch (err) {
    // error handling
  }
}

/***************
 * COMMON
 ***************/

/**
 *
 * @returns
 */
export async function getLoginInfo() {
  return await axios.get(`/api/v1/setting/login_info`);
}

/***************
 * HOME
 ***************/

/**
 *
 * @returns
 */
export async function getHome() {
  return await axios.get(`/api/v1/home`);
}

/**
 *
 * @returns
 */
export async function getHomeReport() {
  return await axios.post(`/api/v1/home/async_report`);
}

/**
 *
 * @returns
 */
export async function postTestMode() {
  return await axios.post(`/api/v1/home/test_mode_onoff`);
}

/**
 *
 * @returns
 */
export async function getIncentive() {
  return await axios.get(`/api/v1/home/get_incentive`);
}

/**
 *
 * @returns
 */
export async function getProgramState() {
  return await axios.get(`/api/v1/home/get_program_onoff`);
}

/**
 *
 * @returns
 */
export async function postProgramState(state) {
  return await axios.post(`/api/v1/home/program_onoff`, {
    onoff: state,
  });
}

/**
 *
 * @returns
 */
export async function getSettingProgress() {
  return await axios.get(`/api/v1/home/get_setting_progress`);
}

/***************
 * SEtTING
 ***************/
export async function getSettingCompany() {
  return await axios.get(`/api/v1/setting/account/company`);
}
export async function getSettingBrandInfo() {
  return await axios.get(`/api/v1/setting/account/brand`);
}
export async function getSettingLoginInfo() {
  return await axios.get(`/api/v1/setting/account/login_user_info`);
}

export async function getUserList() {
  return await axios.get(`/api/v1/setting/account/user`);
}

export async function getTestUserList() {
  return await axios.get(`/api/v1/setting/account/test_user`);
}

/***************
 * INCENTIVE
 ***************/
export async function getAmbIncentive() {
  return await axios.get(`/api/v1/incentive/get_amb_incentive`);
}
export async function postAmbIncentive(params) {
  return await axios.post(`/api/v1/incentive/post_amb_incentive`, params);
}
export async function getGuestIncentive() {
  return await axios.get(`/api/v1/incentive/get_guest_incentive`);
}
export async function postGuestIncentive(params) {
  return await axios.post(`/api/v1/incentive/post_guest_incentive`, params);
}

/***************
 * REPORT
 ***************/
export async function getReport() {
  return await axios.get(`/api/v1/report`);
}

/***************
 * AMBASSADORS
 ***************/
export async function getAmbassadors() {
  return await axios.get(`/api/v1/ambassadors`);
}
export async function getAmbassador(id, params) {
  return await axios.get(`/api/v1/ambassadors/${id}`, {
    params: params,
  });
}
export async function getBlockList() {
  return await axios.get(`/api/v1/ambassador/block`);
}
/***************
 * ORDER
 ***************/
export async function getOrder(params) {
  return await axios.get(`/api/v1/order/get_order`, {
    params: params,
  });
}

/***************
 * OTHER
 ***************/

/**
 * @returns
 */
export async function postConfirmPassword(password) {
  return await axios.post(`/password/confirm`, {
    password: password,
  });
}
