// import Image from 'next/image';
import { useState, useEffect } from 'react';
import lettersPng from '/letters.png';
import iconLogout from '/icons/header/icon-logout.svg';
import iconLogin from '/icons/header/icon-login.svg';
import iconHome from '/icons/header/icon-home.svg';
import iconMail from '/icons/header/icon-mail.svg';

import styles from './Header.module.scss';
// import Link from "next/link";

export const Header = (props) => {
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    let ignore = false;

    if (!ignore && Object.keys(props.company).length != 0) {
      setCompanyName(props.company.info.name);
    }

    return () => {
      ignore = true;
    };
  }, [props]);

  return (
    <header className={styles.header}>
      <div className={styles.header__top}>
        <nav>
          <ul>
            <li>
              <a href=''>ご利用ガイド</a>
            </li>
            <li>
              <a href=''>よくあるご質問</a>
            </li>
            <li className='styles.header__top'>
              <a href=''>
                <img src={iconMail} alt='お問い合わせ' width={20} height={20} />
                お問い合わせ
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className={styles.header__main}>
        <div className={styles.header__main__site}>
          <h1 className={styles.header__main__site__logo}>
            <a href='/'>
              <img src={lettersPng} alt='Letters' width={160} height={44} />
            </a>
          </h1>
          <p className={styles.header__main__site__desc}>
            リファラルマーケティングアプリ
          </p>
        </div>
        <nav className={styles.header__main__nav}>
          <ul>
            <li className={styles.header__main__nav__account}>{companyName}</li>
            <li>
              {props.logined ? (
                <a href='/logout' className={styles.header__main__nav__logout}>
                  <img
                    src={iconLogout}
                    alt='ログアウト'
                    width={32}
                    height={32}
                  />
                  ログアウト
                </a>
              ) : (
                <a href='/login' className={styles.header__main__nav__login}>
                  <img src={iconLogin} alt='ログイン' width={32} height={32} />
                  ログイン
                </a>
              )}
            </li>
            <li>
              <a href='/home' className={styles.header__main__nav__home}>
                <img src={iconHome} alt='ホーム' width={32} height={32} />
                ホーム
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
