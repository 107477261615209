"use client";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'

import styles from './AmbassadorRanking.module.scss';

export const AmbassadorRanking = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.period}>期間：2024.6.1~6.30</div>
      <div className={styles.table}>
        <TableContainer maxWidth={"100%"}>
          <Table>
            <Thead>
              <Tr>
                <Th>月額順位</Th>
                <Th>ユーザー</Th>
                <Th isNumeric>ゲストCV数</Th>
                <Th isNumeric>売上金額</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>🥇1位</Td>
                <Td>y-osako@letters.co.jp</Td>
                <Td isNumeric>1,000</Td>
                <Td isNumeric>100,000,000</Td>
              </Tr>
              <Tr>
                <Td>🥈2位</Td>
                <Td>y-osako@letters.co.jp</Td>
                <Td isNumeric>500</Td>
                <Td isNumeric>90,000,000</Td>
              </Tr>
              <Tr>
                <Td>🥉3位</Td>
                <Td>y-osako@letters.co.jp</Td>
                <Td isNumeric>100</Td>
                <Td isNumeric>90,000,000</Td>
              </Tr>
              <Tr>
                <Td>4位</Td>
                <Td>y-osako@letters.co.jp</Td>
                <Td isNumeric>100</Td>
                <Td isNumeric>90,000,000</Td>
              </Tr>
              <Tr>
                <Td>5位</Td>
                <Td>y-osako@letters.co.jp</Td>
                <Td isNumeric>100</Td>
                <Td isNumeric>90,000,000</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}