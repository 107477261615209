import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
} from '@chakra-ui/react';

import styles from './Ambassadors.module.scss';
import { getAmbassadors as getAmbassadorsApi } from '@/frontend/src/backendApi';

export const AmbassadorsScreen = () => {
  const [ambassadorList, setAmbassadorList] = useState([]);
  const [cvObject, setCvObject] = useState({});
  const [form, setForm] = useState({
    start_date: '',
    end_date: '',
    ambassador_email: '',
  });

  // アンバサダー一覧取得
  const getAmbassadors = async () => {
    const response = await getAmbassadorsApi();
    console.log('get_ambassadors');
    console.log(response.data.data);
    setAmbassadorList(response.data.data.ambassadors ?? []);
    setCvObject(response.data.data.cv_list ?? {});

    setForm(
      response.data.data.form ?? {
        start_date: '',
        end_date: '',
        ambassador_email: '',
      }
    );
  };

  // 日付形式変換
  function formatDatetime(date, sep = '/') {
    const yyyy = date.getFullYear();
    const mm = ('00' + (date.getMonth() + 1)).slice(-2);
    const dd = ('00' + date.getDate()).slice(-2);
    const h = ('00' + date.getHours()).slice(-2);
    const m = ('00' + date.getMinutes()).slice(-2);

    return `${yyyy}${sep}${mm}${sep}${dd} ${h}:${m}`;
  }

  // 再登録日時表示判定
  const reRegisterTime = (v) => {
    if (Date(v.created_at) < Date(v.agreetime)) {
      return formatDatetime(new Date(v.agreetime));
    }
    return '';
  };

  // ゲストCV
  const getCv = (uuid) => {
    return cvObject[uuid].cv ? Number(cvObject[uuid].cv).toLocaleString() : 0;
  };

  // 売り上げ金額
  const getSaleAmount = (uuid) => {
    return cvObject[uuid].sale_amount
      ? Number(cvObject[uuid].sale_amount).toLocaleString()
      : 0;
  };

  // fetch
  useEffect(() => {
    getAmbassadors();
  }, []);

  return (
    <>
      <h2 className={styles.title}>アンバサダー管理</h2>
      <Spacer size='16px' />
      <h3 className={styles.subtitle}>
        アンバサダー（紹介プログラム登録者）一覧
      </h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここではアンバサダー一覧を確認でき、メール送信などができます。
      </p>
      <Spacer size='24px' />

      <Card>
        <form>
          <VStack spacing='16px'>
            <HStack spacing='24px' width={'100%'}>
              <FormControl flex={1}>
                <FormLabel fontSize={14}>メールアドレス</FormLabel>
                <Input
                  type='text'
                  value={form.ambassador_email}
                  onChange={() => {}} // TODO:
                />
              </FormControl>
              <FormControl width={'fit-content'}>
                <FormLabel fontSize={14}>
                  登録日時表示対象期間（最大100日）
                </FormLabel>
                <HStack spacing='8px'>
                  <InputGroup size='md'>
                    <Input
                      type='date'
                      placeholder='2024/01/01'
                      name='start_date'
                      className={styles.input}
                      minWidth={180}
                      value={form.start_date}
                      onChange={() => {}} // TODO:
                    />
                  </InputGroup>
                  <span>〜</span>
                  <InputGroup size='md'>
                    <Input
                      type='date'
                      placeholder='2024/01/31'
                      name='end_date'
                      className={styles.input}
                      minWidth={180}
                      value={form.end_date}
                      onChange={() => {}} // TODO:
                    />
                  </InputGroup>
                </HStack>
              </FormControl>
            </HStack>
          </VStack>
          <Spacer size='16px' />
          <Button
            type='submit'
            size={'md'}
            variant={'solid'}
            colorScheme={'blue'}
          >
            検索する
          </Button>
        </form>
      </Card>

      <Spacer size='32px' />

      <Card
        title='検索結果'
        action={
          <HStack spacing='16px'>
            <Button size='sm' variant='solid' colorScheme='blue'>
              全アンバサダーへフリーメールを送信する
            </Button>
            <Button size='sm' variant='outline' colorScheme='blue'>
              CSV出力
            </Button>
          </HStack>
        }
      >
        <div className={styles.table}>
          <TableContainer maxWidth={'100%'}>
            <Table>
              <Thead>
                <Tr>
                  <Th>メールアドレス</Th>
                  <Th>登録日時</Th>
                  <Th>再登録日時</Th>
                  <Th>ゲストCV数</Th>
                  <Th>売上金額</Th>
                  <Th width={'60px'}>操作</Th>
                </Tr>
              </Thead>
              <Tbody>
                {ambassadorList.map((v) => {
                  return (
                    <Tr key={v.id}>
                      <Td>{v.email}</Td>
                      <Td>{formatDatetime(new Date(v.created_at))}</Td>
                      <Td>{reRegisterTime(v)}</Td>
                      <Td>{getCv(v.uuid)}</Td>
                      <Td>{getSaleAmount(v.uuid)}</Td>
                      <Td>
                        <a href={`/ambassadors/${v.id}`}>
                          <Button
                            size={'xs'}
                            variant={'solid'}
                            colorScheme={'blue'}
                          >
                            詳細
                          </Button>
                        </a>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </>
  );
};
