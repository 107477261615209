'use client';

import { Spacer } from '../../elements/spacer/Spacer';
import { Card } from '../../elements/cards/Card';
import { HelpText } from '@/components/elements/helpText/HelpText';
import { Button, Stack, Input, Select } from '@chakra-ui/react';
import { ChevronUpIcon } from '@chakra-ui/icons';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/de.js';
import React, { useState } from 'react';

import styles from './Guest.module.scss';

export const GuestScreen = () => {
  const [previewDeviceType, setPreviewDeviceType] = useState('mobile');
  const [editDeviceType, setEditDeviceType] = useState('mobile');
  const [isEdit, setIsEdit] = useState(false);

  const froalaConfig = {
    Key: 'YC5C3E3B2c1A6B5A4B3A3B2C2G3C5C1D-17dlB-9D-8iJ-7A-21A8sD-13B1yd==',
    height: 300,
    toolbarButtons: [
      [
        'fontFamily',
        'fontSize',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
      ],
      ['textColor', 'backgroundColor'],
      ['subscript', 'superscript'],
      [
        'alignLeft',
        'alignCenter',
        'formatOLSimple',
        'alignRight',
        'alignJustify',
        'formatOL',
        'formatUL',
        'paragraphFormat',
        'paragraphStyle',
        'lineHeight',
        'outdent',
        'indent',
        'quote',
      ],
      ['insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable'],
      ['undo', 'redo'],
      ['clearFormatting', 'selectAll', 'html'],
      ['fullscreen'],
    ],
  };
  const html = `
    <div style="margin-top: 70px;">
      <div class="row justify-content-center">
        <div
          class="text-center input_container __col-md-8 col-12"
          data-inputtype="img"
          data-id="mainimg"
          data-label="メインビジュアル"
        >
          <img
            src="https://letters.co.jp/uploads/image/image/2/a065d3a7-b5cc-4007-8484-9274cfb9c31b.jpeg"
            alt=""
            style="width: 100%; max-width: 1050px;"
          />
        </div>
      </div>
      <h1>
        <div class="row justify-content-sm-center mt-3">
          <div
            class="input_container __col-sm-10 col-12 __col-md-8 __col-lg-6"
            style="font-size: 1.6rem"
            data-inputtype="text"
            data-id="title"
            data-label="タイトル"
          >
            友人を紹介して嬉しくなろう！！！
          </div>
        </div>
      </h1>
      <div class="row justify-content-sm-center">
        <div
          class="input_container text-muted __col-sm-10 col-12 __col-md-8 __col-lg-6"
          data-inputtype="text"
          data-id="subtitle"
          data-label="サブタイトル"
        ></div>
      </div>
      <div class="row justify-content-sm-center">
        <div class="text-muted __col-sm-10 col-12 __col-md-8 __col-lg-6">
          提供：株式会社登録テスト
        </div>
      </div>
      <div class="row justify-content-sm-center mt-2">
        <div class="__col-sm-10 col-12 __col-md-8 __col-lg-6">
          <div
            class="card"
            style="background-color: #5271FF;border-radius: 0.4rem;color: white;"
          >
            <div class="card-body py-3">
              <div
                class="px-2 input_container"
                style="font-family: 'Roboto', sans-serif;font-size: 1.1rem;"
                data-inputtype="text"
                data-id="amb_incen_title"
                data-label="アンバサダーインセンティブタイトル"
              >
                PRESENT FOR YOU
              </div>
              <div
                class="py-3 input_container"
                style="font-weight: bold;font-size: 1.4rem;text-align: center;"
                data-inputtype="text"
                data-id="amb_incen"
                data-label="アンバサダーインセンティブ"
              >
                嬉しい気持ち
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: none;">
        <div
          class="input_container"
          data-inputtype="color"
          data-id="amb_incentive_color"
          data-label="アンバサダーインセンティブ背景色"
        ></div>
      </div>
      <div
        id="guest_incen_container"
        class="row justify-content-sm-center mt-3 d-none"
      >
        <div class="__col-sm-10 col-12 __col-md-8 __col-lg-6">
          <div
            class="card"
            style="border-radius: 0.4rem;border-color: #dc00ff;color: #dc00ff;"
          >
            <div class="card-body py-3">
              <div
                class="px-2 input_container"
                style="font-family: 'Roboto', sans-serif;font-size: 1.1rem;"
                data-inputtype="text"
                data-id="gst_incen_title"
                data-label="ゲストインセンティブタイトル"
              >
                PRESENT FOR YOUR FRIEND
              </div>
              <div
                id="guest_incen_text"
                class="py-3 input_container"
                style="font-weight: bold;font-size: 1.4rem;text-align: center;"
                data-inputtype="text"
                data-id="gst_incen"
                data-label="ゲストインセンティブ"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: none;">
        <div
          class="input_container"
          data-inputtype="color"
          data-id="guest_incentive_color"
          data-label="ゲストインセンティブ枠色"
        ></div>
      </div>
      <div class="row justify-content-sm-center mt-3">
        <div
          class="input_container __col-sm-10 col-12 __col-md-8 __col-lg-6"
          data-inputtype="text"
          data-id="description"
          data-label="説明文"
        >
          登録ブランドでは、現在、ユーザー様限定のお得なご友人紹介キャンペーンを実施しています。
          <br />
          <br />
          是非、この機会に本プログラムにご参加ください。
        </div>
      </div>
      <div class="row justify-content-sm-center mt-3">
        <div
          class="__col-sm-10 col-12 __col-md-8 __col-lg-6"
          style="font-size: 1.2rem;font-weight: bold;"
        >
          <i class="fas fa-angle-right mr-1"></i>参加方法
        </div>
      </div>
      <div class="row justify-content-sm-center mt-3">
        <div
          class="input_container __col-sm-10 col-12 __col-md-8 __col-lg-6"
          data-inputtype="text"
          data-id="joinhelp"
          data-label="参加方法"
        >
          Eメールを登録するだけで、あなただけの専用コードが発行されます。そのコードをご友人にお知らせください。
          <br />
          ご友人がそのコードから購入した際は、お礼としてAMB_INCEN_TYPEをお支払いいたします。
        </div>
      </div>
      <div class="row justify-content-sm-center mt-3">
        <div
          class="__col-sm-10 col-12 __col-md-8 __col-lg-6"
          style="font-size: 1.2rem;font-weight: bold;"
        >
          <i class="fas fa-angle-right mr-1"></i>注意事項
        </div>
      </div>
      <div class="row justify-content-sm-center mt-3">
        <div class="__col-sm-10 col-12 __col-md-8 __col-lg-6">
          <ol>
            <li>
              [{" "}
              <u style="color: #01ab87;font-style: italic;">
                @letters.co.jp
              </u>{" "}
              ]からのメールを受信できるように設定をお願いいたします。
            </li>
            <li>
              謝礼などのご連絡は、ご記入いただいたEメールにお送りするため、必ず有効なアドレスをご記入ください。
            </li>
            <li>
              参加完了後に事務局からメールをお送りいたしますので、届かない場合は、Eメールのアドレスと設定を再度ご確認ください。
            </li>
            <li>
              本キャンペーン内容は予告なく変更、終了する可能性がある旨、予めご了承ください。
            </li>
            <li>
              本キャンペーンは、株式会社レターズが提供するシステムを使って個人情報等厳重に管理、運用されております。
            </li>
          </ol>
        </div>
      </div>
      <div class="row justify-content-center my-3">
        <div
          class="text-center input_container __col-10 col-12"
          data-inputtype="img"
          data-id="footer_logoimg"
          data-label="企業ロゴイメージ"
        >
          <img
            src=""
            alt=""
            style="max-height: 60px; max-width: 200px;"
          />
        </div>
      </div>
    </div>
  `;

  return (
    <>
      <h2 className={styles.title}>ドキュメント管理</h2>
      <Spacer size='16px' />
      <h3 className={styles.subtitle}>紹介ページ編集</h3>
      <Spacer size='8px' />
      <p className={styles.description}>
        ここでは紹介ページ（LP）の見え方を管理します。画像を設定することもできます。
      </p>
      <Spacer size='24px' />

      <Card title='紹介ページ編集' forGuest={true}>
        <Spacer size='16px' />
        <nav className={styles.nav}>
          <ul>
            <li
              className={previewDeviceType === 'mobile' ? styles.active : ''}
              onClick={() => setPreviewDeviceType('mobile')}
            >
              モバイル
            </li>
            <li
              className={previewDeviceType === 'pc' ? styles.active : ''}
              onClick={() => setPreviewDeviceType('pc')}
            >
              PC
            </li>
          </ul>
        </nav>

        <Spacer size='16px' />

        <div className={styles.preview}>
          <nav className={styles.tab}>
            <ul className={styles.tab__items}>
              <li className={styles.tab__item}>
                <a href='/document/ambassador/'>アンバサダー</a>
              </li>
              <li className={`${styles.tab__item} ${styles.tab__item__active}`}>
                <a href='/document/guest'>ゲスト</a>
              </li>
            </ul>
          </nav>

          <Spacer size='24px' />

          <div className={styles.preview__container}>
            <div
              className={
                previewDeviceType === 'mobile'
                  ? styles.preview__mobile
                  : styles.preview__desktop
              }
            >
              <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
          </div>
        </div>

        <Spacer size='24px' />

        <p className={styles.note}>
          ※最下部にアンバサダー参加用のリンクが表示されます。
        </p>
      </Card>

      <Spacer size='32px' />

      <Card
        title='編集'
        id='edit'
        className={`${styles.edit} ${isEdit ? styles.edit__active : ''}`}
      >
        <Spacer size='16px' />
        <nav className={styles.nav}>
          <ul>
            <li
              className={editDeviceType === 'mobile' ? styles.active : ''}
              onClick={() => setEditDeviceType('mobile')}
            >
              モバイル
            </li>
            <li
              className={editDeviceType === 'pc' ? styles.active : ''}
              onClick={() => setEditDeviceType('pc')}
            >
              PC
            </li>
          </ul>
        </nav>
        <Spacer size='16px' />

        <Button
          size='xs'
          variant='outline'
          colorScheme='blue'
          rightIcon={<ChevronUpIcon />}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          現在の内容を確認する
        </Button>

        <Spacer size='16px' />

        <Stack direction='row' spacing='32px' width='100%' align='start'>
          <div className={styles.edit__preview}>
            <div
              className={
                previewDeviceType === 'mobile'
                  ? styles.edit__preview__mobile
                  : styles.edit__preview__desktop
              }
            >
              <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
          </div>
          <div className={styles.edit__form}>
            <form>
              <label className={styles.edit__form__label}>インセンティブ</label>
              <Spacer size='8px' />
              <Select size='md' w='320px'>
                <option value='with'>インセンティブあり</option>
                <option value='without'>インセンティブなし</option>
              </Select>
              <Spacer size='32px' />

              <label className={styles.edit__form__label}>タイトル</label>
              <Spacer size='8px' />
              <Input
                size='md'
                variant='outline'
                placeholder='友人を紹介してクーポンを手に入れよう！'
              />
              <Spacer size='32px' />

              <Stack
                direction='row'
                spacing='8px'
                align='center'
                justify='start'
              >
                <label className={styles.edit__form__label}>テキスト</label>
                <HelpText label='テキストを入力してください' />
              </Stack>
              <Spacer size='8px' />
              <FroalaEditorComponent tag='textarea' config={froalaConfig} />
            </form>
          </div>
        </Stack>
      </Card>

      <div className={styles.action}>
        {!isEdit && (
          <Button
            size='lg'
            variant='solid'
            colorScheme={'blue'}
            className={styles.action__edit}
            width='450px'
            height='48px'
            onClick={() => {
              setIsEdit(!isEdit);
              const target = document.getElementById('edit');
              const rect = target.getBoundingClientRect().top;
              const offset = window.pageYOffset;
              const gap = 80;
              const targetY = rect + offset - gap;
              window.scrollTo({ top: targetY, behavior: 'smooth' });
            }}
          >
            編集する
          </Button>
        )}
        {isEdit && (
          <Stack
            direction='row'
            spacing='8px'
            width='100%'
            align='center'
            justify='center'
          >
            <Button
              size='lg'
              variant='outline'
              colorScheme={'blue'}
              className={styles.action__cancel}
              width='240px'
              height='48px'
              onClick={() => setIsEdit(!isEdit)}
            >
              キャンセル
            </Button>
            <Button
              size='lg'
              variant='solid'
              colorScheme={'blue'}
              className={styles.action__save}
              width='240px'
              height='48px'
            >
              保存する
            </Button>
          </Stack>
        )}
      </div>
    </>
  );
};
