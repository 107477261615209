import { useState, useEffect } from 'react';
import { Grid } from '@chakra-ui/react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import { ActivateProgram } from '@/frontend/components/feature/Home/ActivateProgram';
import { Incentive } from '@/frontend/components/feature/Home/Incentive';
import { NextAction } from '@/frontend/components/feature/Home/NextAction';
import { BannerSection } from '@/frontend/components/feature/Home/BannerSection';
import { ConversionTable } from '@/frontend/components/feature/Home/ConversionTable';
import { Review } from '@/frontend/components/feature/Home/Review';
import { AmbassadorRanking } from '@/frontend/components/feature/Home/AmbassadorRanking';

import styles from './Home.module.scss';
import {
  getHomeReport as getReportApi,
  getIncentive as getIncentiveApi,
  getProgramState as getProgramStateApi,
  postProgramState as postProgramStateApi,
  getSettingProgress as getSettingProgressApi,
} from '@/frontend/src/backendApi';

export const HomeScreen = () => {
  const [report, setReport] = useState({});
  const [ambIncentive, setAmbIncentive] = useState('-');
  const [guestIncentive, setGuestIncentive] = useState('-');
  const [programSate, setProgramState] = useState(false);
  const [ambSettingProgress, setAmbSettingProgress] = useState(false);
  const [guestSettingProgress, setGuestSettingProgress] = useState(false);
  // const [programStatus, setProgramStatus] = useState(false);

  // 直近1ヶ月の成果取得
  const getReport = async () => {
    const response = await getReportApi();
    console.log('report');
    console.log(response.data.data);
    setReport({ ...response.data.data });
  };

  // インセンティブ取得
  const getIncentive = async () => {
    const response = await getIncentiveApi();
    console.log('incentive');
    console.log(response.data.data);
    setAmbIncentive(response.data.data.amb_incentive ?? '-');
    setGuestIncentive(response.data.data.guest_incentive ?? '-');
  };

  // プログラム有効状態取得
  const getProgramState = async () => {
    const response = await getProgramStateApi();
    console.log('program_state');
    console.log(response.data.data);
    setProgramState(response.data.data.enable);
  };
  // プログラム有効/無京
  const postProgramState = async (state) => {
    const response = await postProgramStateApi(state);
    console.log('program_state');
    console.log(response.data.data);
    setProgramState(response.data.data.enable);
  };

  // 簡単スタート設定
  const getSettingProgress = async () => {
    const response = await getSettingProgressApi();
    console.log('setting_progress');
    console.log(response.data.data);
    setAmbSettingProgress(response.data.data.is_amb_setting ?? false);
    setGuestSettingProgress(response.data.data.is_guest_setting ?? false);
  };

  // fetch
  useEffect(() => {
    // 直近1ヶ月の成果情報
    getReport();
    getIncentive();
    getProgramState();
    getSettingProgress();

    // TODO: アンバサダー情報取得(ランキング)
    // const getLoginInfo = async () => {
    //   const response = await getLoginInfoApi();
    //   console.log(response.data.data);
    //   setCompanyInfo({ ...response.data.data });
    // };
  }, []);

  return (
    <>
      <h2 className={styles.title}>ホーム</h2>
      <Spacer size='24px' />

      <Grid templateColumns='1fr 2fr' gap='16px'>
        <ActivateProgram
          programSate={programSate}
          postProgramState={postProgramState}
        />
        <Incentive
          ambIncentive={ambIncentive}
          guestIncentive={guestIncentive}
        />
      </Grid>

      <Spacer size='32px' />

      <NextAction
        ambSettingProgress={ambSettingProgress}
        guestSettingProgress={guestSettingProgress}
      />

      <Spacer size='32px' />

      <BannerSection />

      <Spacer size='32px' />

      <Card title='直近1ヶ月の成果（前月同日から本日まで）'>
        <ConversionTable
          isActivated={true}
          report={report}
          getReport={getReport}
        />
      </Card>

      <Spacer size='32px' />

      <Card>
        <Review />
      </Card>

      <Spacer size='32px' />

      <Card title='月間アンバサダーランキング' helpTitle='ランキングについて'>
        <AmbassadorRanking />
      </Card>
    </>
  );
};
