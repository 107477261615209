'use client';

import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';

import styles from './View.module.scss';
import { getAmbassador as getAmbassadorApi } from '@/frontend/src/backendApi';

export const ViewScreen = () => {
  const [ambassador, setAmbassador] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(false);

  // アンバサダー取得
  const getAmbassador = async () => {
    // 末尾取得
    const id = location.pathname.replace(/\/+$/, '').split('/').pop();
    const params = {
      page: page,
    };
    const response = await getAmbassadorApi(id, params);
    console.log('get_ambassador');
    console.log(response.data.data);
    setAmbassador(response.data.data.ambassador ?? {});
    setOrderList(
      response.data.data.order_list ? response.data.data.order_list.data : []
    );
    setNext(
      response.data.data.order_list ? response.data.data.order_list.next : false
    );
  };

  // 日付形式変換
  function formatDatetime(date, sep = '/') {
    const yyyy = date.getFullYear();
    const mm = ('00' + (date.getMonth() + 1)).slice(-2);
    const dd = ('00' + date.getDate()).slice(-2);
    const h = ('00' + date.getHours()).slice(-2);
    const m = ('00' + date.getMinutes()).slice(-2);

    return `${yyyy}${sep}${mm}${sep}${dd} ${h}:${m}`;
  }

  // fetch
  useEffect(() => {
    getAmbassador();
  }, []);

  // TODO: 年間コンバージョン数、売り上げを取得するAPI追加(sum)

  return (
    <>
      <h2 className={styles.title}>アンバサダー管理</h2>
      <Spacer size='16px' />
      <h3 className={styles.subtitle}>
        アンバサダー（紹介プログラム登録者）詳細
      </h3>
      <Spacer size='24px' />

      <Card>
        <div className={styles.info}>
          <table>
            <tbody>
              <tr>
                <th>メールアドレス</th>
                <td>{ambassador.email}</td>
              </tr>
              <tr>
                <th>紹介用URL</th>
                <td>{`https://letters.co.jp/invite/${ambassador.uuid}`}</td>
              </tr>
              <tr>
                <th>アンバサダー登録日時</th>
                <td>{formatDatetime(new Date(ambassador.created_at))}</td>
              </tr>
              <tr>
                <th>年間コンバージョン数</th>
                <td>100</td>
              </tr>
              <tr>
                <th>年間売上金額</th>
                <td>￥10,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>

      <Spacer size='32px' />

      <Card title='紹介情報'>
        <div className={styles.table}>
          <TableContainer maxWidth={'100%'}>
            <Table>
              <Thead>
                <Tr>
                  <Th>購入日時</Th>
                  <Th>注文ID</Th>
                  <Th>注文番号</Th>
                  <Th>売上金額</Th>
                  <Th>購入者氏名</Th>
                  <Th>購入者メールアドレス</Th>
                </Tr>
              </Thead>
              <Tbody>
                {orderList.map((v) => {
                  return (
                    <Tr key={v.order_id}>
                      <Td>{formatDatetime(new Date(v.datetime))}</Td>
                      <Td>{v.order_id}</Td>
                      <Td>{v.order_number}</Td>
                      <Td>{Number(v.sales_amount).toLocaleString()}</Td>
                      <Td>{`${v.last_name} ${v.first_name}`}</Td>
                      <Td>{v.email}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </Card>

      <Spacer size='32px' />

      <HStack align={'center'} justify={'center'}>
        <a href='/ambassadors'>
          <Button
            size='lg'
            variant='outline'
            colorScheme='blue'
            leftIcon={<ChevronLeftIcon />}
            backgroundColor={'white'}
          >
            アンバサダー一覧に戻る
          </Button>
        </a>
      </HStack>
    </>
  );
};
