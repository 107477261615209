'use client';

import { BannerItem } from './BannerItem';
import styles from './BannerSection.module.scss';
import guideBanner from '/home/banner-guide.png';
import inviteBanner from '/home/banner-invite.png';
import caseStudyBanner from '/home/banner-case-study.png';

export const BannerSection = () => {
  return (
    <div className={styles.container}>
      <BannerItem
        link='https://www.google.com'
        image={guideBanner}
        title='ご利用ガイド'
      />
      <BannerItem
        link='https://www.google.com'
        image={inviteBanner}
        title='お友達紹介のコツ'
      />
      <BannerItem
        link='https://www.google.com'
        image={caseStudyBanner}
        title='売り上げを伸ばす活用事例'
      />
    </div>
  );
};
