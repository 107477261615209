import { useState, useEffect } from 'react';
import { Spacer } from '@/frontend/components/elements/spacer/Spacer';
import { Card } from '@/frontend/components/elements/cards/Card';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
} from '@chakra-ui/react';
import { InputGroup, Input } from '@chakra-ui/react';

import styles from './Report.module.scss';
import { getReport as getReportApi } from '@/frontend/src/backendApi'; // TODO:

export const ReportScreen = () => {
  const params = {
    report_search_form: {
      start_date: '',
      end_date: '',
    },
  };
  const [summaryObject, setSummaryObject] = useState({});
  const [total, setTotal] = useState({});
  const [form, setForm] = useState({
    start_date: '',
    end_date: '',
  });

  // レポート取得
  const getReport = async () => {
    const response = await getReportApi();
    console.log('get_report');
    console.log(response.data.data);

    setForm(
      response.data.data.form ?? {
        start_date: '',
        end_date: '',
      }
    );
    setSummaryObject(response.data.data.report.summary_list ?? []);
    setTotal(response.data.data.report.total_list ?? []);
  };

  const getSalesPer = (v) => {
    return Number(v.site_induction_num) != 0
      ? Math.round(
          (((parseFloat(v.letters_buy_count) /
            parseFloat(v.site_induction_num)) *
            100.0) /
            10) *
            10
        )
      : 0;
  };

  // 日付形式変換
  function formatDate(date, sep = '/') {
    const yyyy = date.getFullYear();
    const mm = ('00' + (date.getMonth() + 1)).slice(-2);
    const dd = ('00' + date.getDate()).slice(-2);

    return `${yyyy}${sep}${mm}${sep}${dd}`;
  }

  // fetch
  useEffect(() => {
    getReport();
  }, []);

  return (
    <>
      <h2 className={styles.title}>レポート</h2>

      <Spacer size='16px' />

      <p className={styles.description}>ここでは期間を設定して検索できます。</p>

      <Spacer size='24px' />

      <Card>
        <form>
          <label className={styles.label}>期間</label>
          <Spacer size='8px' />
          <HStack spacing='8px'>
            <InputGroup size='md' width='214px'>
              <Input
                type='date'
                placeholder='2024/01/01'
                name='start_date'
                className={styles.input}
                required
              />
            </InputGroup>
            <span>〜</span>
            <InputGroup size='md' width='214px'>
              <Input
                type='date'
                placeholder='2024/01/31'
                name='end_date'
                className={styles.input}
                required
              />
            </InputGroup>
          </HStack>
          <Spacer size='16px' />
          <Button
            type='submit'
            size={'md'}
            variant={'solid'}
            colorScheme={'blue'}
          >
            検索する
          </Button>
        </form>
      </Card>

      <Spacer size='32px' />

      <Card
        title='検索結果'
        action={
          <Button size='sm' variant='outline' colorScheme='blue'>
            CSV出力
          </Button>
        }
      >
        <div className={styles.table}>
          <TableContainer maxWidth={'100%'}>
            <Table>
              <Thead>
                <Tr>
                  <Th>日付</Th>
                  <Th>リクルート数</Th>
                  <Th>リクルートPV数</Th>
                  <Th>新規アンバサダー数</Th>
                  <Th>紹介ページPV数</Th>
                  <Th>サイト誘導数</Th>
                  <Th>Letters経由の購入数（ゲストCV）</Th>
                  <Th>Letters売上金額</Th>
                  <Th>Letters購入率</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.keys(summaryObject).map((key) => {
                  {
                    /* TODO: メソッドへ + 3桁区切り */
                  }
                  return (
                    <Tr key={key}>
                      <Td>{formatDate(new Date(key))}</Td>
                      <Td>{summaryObject[key].recruit_num ?? 0}</Td>
                      <Td>{summaryObject[key].recruit_pv ?? 0}</Td>
                      <Td>{summaryObject[key].new_ambassador_num ?? 0}</Td>
                      <Td>{summaryObject[key].invitation_pv ?? 0}</Td>
                      <Td>{summaryObject[key].site_induction_num ?? 0}</Td>
                      <Td>{summaryObject[key].letters_buy_count ?? 0}</Td>
                      <Td>
                        ￥
                        {Number(
                          summaryObject[key].letters_amount
                        ).toLocaleString() ?? 0}
                      </Td>
                      <Td>
                        {summaryObject[key].site_induction_num
                          ? getSalesPer(summaryObject[key])
                          : 0}
                        %
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
              <Tfoot backgroundColor={'rgba(235, 248, 255, 1)'}>
                <Tr>
                  <Td>合計</Td>
                  <Td>{total.recruit_num ?? 0}</Td>
                  <Td>{total.recruit_pv ?? 0}</Td>
                  <Td>{total.new_ambassador_num ?? 0}</Td>
                  <Td>{total.invitation_pv ?? 0}</Td>
                  <Td>{total.site_induction_num ?? 0}</Td>
                  <Td>{total.letters_buy_count ?? 0}</Td>
                  <Td>
                    ￥{Number(total.letters_amount).toLocaleString() ?? 0}
                  </Td>
                  <Td>{total.site_induction_num ? getSalesPer(total) : 0}%</Td>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </>
  );
};
