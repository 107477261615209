'use client';

import { useState, useEffect } from 'react';
import { Switch } from '@chakra-ui/react';
import { HelpText } from '@/frontend/components/elements/helpText/HelpText';
import iconAmbassador from '/icons/icon-ambassador.svg';
import iconGuest from '/icons/icon-guest.svg';
import iconBag from '/icons/icon-bag.svg';

import styles from './ConversionTable.module.scss';

import { postTestMode as postTestModeApi } from '@/frontend/src/backendApi';

export const ConversionTable = (props) => {
  // const [isIncludeTest, setIsIncludeTest] = useState(false); // TODO: 削除

  const setTestMode = async () => {
    await postTestModeApi();
    console.log('----------------');
    props.getReport();
  };

  return (
    <section className={styles.container}>
      <div className={styles.ambassador}>
        <div className={styles.ambassador__title}>
          <img
            src={iconAmbassador}
            alt='アンバサダー'
            className={styles.ambassador__icon}
          />
          アンバサダー
        </div>
        <div className={styles.ambassador__card}>
          <div className={styles.ambassador__card__title}>
            リクルーティングメール配信数
          </div>
          <div className={styles.ambassador__card__count}>
            {Number(props.report.recruit_nun ?? 0).toLocaleString()}
            <span className={styles.ambassador__card__count__unit}>通</span>
          </div>
        </div>
        <div className={styles.ambassador__card}>
          <div className={styles.ambassador__card__title}>
            リクルーティングメール閲覧数
          </div>
          <div className={styles.ambassador__card__count}>
            {Number(props.report.recruit_pv ?? 0).toLocaleString()}
            <span className={styles.ambassador__card__count__unit}>件</span>
          </div>
        </div>
        <div className={styles.ambassador__card}>
          <div className={styles.ambassador__card__title}>
            アンバサダー登録数
          </div>
          <div className={styles.ambassador__card__count}>
            {Number(props.report.new_ambassador_num ?? 0).toLocaleString()}
            <span className={styles.ambassador__card__count__unit}>件</span>
          </div>
        </div>
      </div>
      <div className={styles.guest}>
        <div className={styles.guest__title}>
          <img src={iconGuest} alt='ゲスト' className={styles.guest__icon} />
          ゲスト
        </div>
        <div className={styles.guest__card}>
          <div className={styles.guest__card__title}>ゲストページ閲覧数</div>
          <div className={styles.guest__card__count}>
            {Number(props.report.invitation_pv ?? 0).toLocaleString()}
            <span className={styles.guest__card__count__unit}>件</span>
          </div>
        </div>
        <div className={styles.guest__card}>
          <div className={styles.guest__card__title}>購入サイト誘導数</div>
          <div className={styles.guest__card__count}>
            {Number(props.report.site_induction_num ?? 0).toLocaleString()}
            <span className={styles.guest__card__count__unit}>件</span>
          </div>
        </div>
        <div className={styles.guest__card}>
          <div className={styles.guest__card__title}>
            Letters経由の購入数（ゲストCV数）
            <HelpText text='' label='ここにヘルプテキストを記載' />
          </div>
          <div className={styles.guest__card__count}>
            {Number(props.report.letters_buy_count ?? 0).toLocaleString()}
            <span className={styles.guest__card__count__unit}>件</span>
          </div>
        </div>
      </div>
      <div className={styles.sales}>
        <div className={styles.sales__title}>
          <img src={iconBag} alt='売上' className={styles.sales__icon} />
          売上
        </div>
        <div className={styles.sales__card}>
          <div className={styles.sales__card__title}>Letters経由の売上金額</div>
          <div className={styles.sales__card__count}>
            {Number(props.report.letters_amount ?? 0).toLocaleString()}
            <span className={styles.sales__card__count__unit}>円</span>
          </div>
        </div>
        <div className={styles.sales__card}>
          <div className={styles.sales__card__title}>Letters経由の購入率</div>
          <div className={styles.sales__card__count}>
            {Number(props.report.sales_per ?? 0).toLocaleString()}
            <span className={styles.sales__card__count__unit}>%</span>
          </div>
        </div>
        <div className={styles.sales__card}>
          <div className={styles.sales__card__title}>
            テストユーザーを売上に反映
          </div>
          <div className={styles.sales__card__button}>
            <Switch
              size='lg'
              isChecked={props.report.test_mode}
              onChange={setTestMode} // TODO: test_mode_on_off post
            />
            {props.report.test_mode ? (
              <div className={styles.program__content__activated}>反映中</div>
            ) : (
              <div className={styles.program__content__deactivated}>
                反映していません
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
